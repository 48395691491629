import React from 'react';
import HeroSection from '../components/homeComponents/HeroSection';
import CurriculumSection from '../components/homeComponents/CurriculumSection';
import WorkexperienceApproach from '../components/homeComponents/WorkexperienceApproach';
import MasterApproach from '../components/homeComponents/MasterApproach';
import PlacementAssistance from '../components/homeComponents/PlacementAssistance';
import Comparision from '../components/homeComponents/Comparision';
import FAQ from '../components/homeComponents/FAQ';
import LogoCarousel from '../components/homeComponents/LogoCarousel';


const Home = () => {


    return (
        <div>
            <HeroSection />
            <CurriculumSection />
            <LogoCarousel />
            <MasterApproach />
            <WorkexperienceApproach />
            <PlacementAssistance />
            <Comparision />
            <FAQ />
        </div>
    );
};

export default Home;
