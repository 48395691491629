// Footer.js
import React from 'react';
import '../styles/footer.css';
import logo from './homeComponents/asset/logo-update.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer rubik-hero-section bg-gradient-to-r from-black to-customLightBlack">
      <div className="footer-content">
        
        {/* Logo and Tagline */}
        <div className="footer-logo">
          <img src={logo} alt="Learnify Logo" className="logo" />
        </div>

        {/* Contact Information */}
<div className="footer-contact">
  <h4 className="manrope-hero-section">Contact Us</h4>
  <p>Email: <a href="mailto:support@learnifyhub.in" className="text-blue-500 hover:text-blue-700">support@learnifyhub.in</a></p>
{/*   <p>Phone: <a href="tel:+919220516390" className="text-blue-500 hover:text-blue-700">+91 9220516390</a></p> */}
</div>


        {/* Social Media Links */}
        <div className="footer-social">
          <a href="https://www.linkedin.com/company/learnify-tech" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a href="https://x.com/Learnify_Tech" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/learnify.hub/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>

      {/* Footer Bottom with Privacy Policy, Terms & Conditions, and Contact Us Links */}
      <div className="footer-bottom">
        <p>© 2024 Learnify. All rights reserved.</p>
        <p>
          <Link to="/privacypolicy" className="privacy-policy-link" onClick={() => window.scrollTo(0, 0)}>
            Privacy Policy
          </Link> | 
          <Link to="/terms" className="privacy-policy-link" onClick={() => window.scrollTo(0, 0)}>
            Terms & Conditions
          </Link> |
          <Link to="/contactus" className="privacy-policy-link" onClick={() => window.scrollTo(0, 0)}>
            Contact Us
          </Link>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
