import React, { useState } from 'react';
import axiosInstance from '../api/axiosInstance';

const ContactUs = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [buttonText, setButtonText] = useState('Send Message');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axiosInstance.post('callback', {
        firstName,
        lastName,
        email,
        phone,
        message,
      });
      setButtonText('Sent!');
      setIsSubmitted(true);
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('+91');
      setMessage('');

      setTimeout(() => {
        setButtonText('Request Callback');
        setIsSubmitted(false);
      }, 1000);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to send request. Please try again.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-8 py-12 bg-gradient-to-r from-blue-50 via-blue-100 to-white text-gray-900 rounded-lg shadow-lg">
      <h1 className="text-4xl font-bold text-center text-blue-600 mb-8">Contact Us</h1>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-2">
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="w-full p-4 border-2 border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-lg"
            required
          />
        </div>
        <div>
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-2">
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="w-full p-4 border-2 border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-lg"
            required
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-4 border-2 border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-lg"
            required
          />
        </div>
        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-2">
            Phone
          </label>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="w-full p-4 border-2 border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-lg"
            required
          />
        </div>
        <div>
          <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-2">
            Message
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full p-4 border-2 border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-lg"
            rows="5"
            required
          />
        </div>
        <div>
          <button
            type="submit"
            className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg"
          >
            {buttonText}
          </button>
        </div>
      </form>
      {isSubmitted && (
        <div className="mt-6 text-center text-lg text-green-500">
          <p>Thank you for reaching out! We will get back to you as soon as possible.</p>
        </div>
      )}

      <section className="mt-12 text-center">
  <h2 className="text-2xl font-semibold text-blue-500 mb-4">Our Contact Information</h2>
  <p className="text-lg leading-relaxed mb-6">
    If you need immediate assistance or have any inquiries, feel free to contact us through the following channels:
  </p>
  <ul className="ml-6 text-lg leading-relaxed list-none">
    <li>Email: <a href="mailto:support@learnifyhub.in" className="text-blue-500 hover:text-blue-700">support@learnifyhub.in</a></li>
{/*     <li>Phone: <a href="tel:+919220516390" className="text-blue-500 hover:text-blue-700">+91 9220516390</a></li> */}
    <li>Address: <span className="text-gray-700">Learnify, Hyderabad, Telangana, India - 500030</span></li>
  </ul>
</section>

    </div>
  );
};

export default ContactUs;
