import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-12 bg-gray-50">
      <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-10">Privacy & Policy</h1>
      
      <section className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Introduction</h2>
        <p className="text-gray-700 leading-relaxed">
          This Privacy Policy is part of the overall Terms and Conditions. Learnify cares about how your personal information is used and shared, and takes your privacy very seriously. By visiting and using this website, learnifyhub.in, or any related services offered by Learnify, you acknowledge that you accept the policies outlined in this Privacy Policy.
        </p>
      </section>

      <section className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Personal Information</h2>
        <p className="text-gray-700 mb-4">We may collect the following data from you, when you use or otherwise interact with our products:</p>
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Name (First name/last name/full name)</li>
          <li>Email ID (both personal and professional)</li>
          <li>Profile/display picture</li>
          <li>Country, state, postcode or city of residence</li>
          <li>Login name, screen name, nickname, or handle</li>
          <li>Telephone number (Home/work/mobile telephone number)</li>
          <li>Postal or other physical address</li>
          <li>Credit/debit card information</li>
          <li>Date of birth</li>
          <li>Gender</li>
          <li>IP addresses and other information collected passively</li>
        </ul>
      </section>

      <section className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Usage of Your Information</h2>
        <p className="text-gray-700 mb-4">
          We will use your Personal Data only in accordance with this privacy policy. If you do not wish us to continue using your Personal Data in this manner, you can request deactivation of your account from account settings.
        </p>
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Personalizing, improving, or operating our Products and business</li>
          <li>Understanding your needs and interests</li>
          <li>Fulfilling requests you make related to the Products</li>
          <li>Providing you with relevant information</li>
          <li>Complying with our legal obligations, resolving disputes with users, enforcing our agreements</li>
          <li>Protecting, investigating, and deterring against fraudulent, harmful, unauthorized, or illegal activity</li>
        </ul>
      </section>

      <section className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Data Subject Rights</h2>
        <p className="text-gray-700 mb-4">
          You have certain rights with respect to your Personal Data as mentioned below. In order to make any of the following requests, you should contact us at [email].
        </p>
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Access: You can request more information about the Personal Data we hold about you.</li>
          <li>Rectification: You can request that we correct or supplement any Personal Data that you believe is incorrect or incomplete.</li>
          <li>Objection: You can contact us to let us know that you object to the collection or use of your Personal Data for certain purposes.</li>
          <li>Deleting data: You can request that we delete some or all of your Personal Data from our systems.</li>
          <li>Processing restrictions: You can always request us to deactivate your account to restrict further processing of your Personal Data.</li>
          <li>Portability: You have the right to ask for a copy of your Personal Data in a machine-readable format.</li>
          <li>Withdrawal of consent: You have the right to withdraw your consent at any time if we are processing your Personal Data based on your consent.</li>
        </ul>
      </section>

      <section className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">How do we protect visitor information?</h2>
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>We use vulnerability scanning and scanning to PCI standards annually.</li>
          <li>We use regular Malware Scanning.</li>
          <li>We use SSL certificate to encrypt all the data being transferred.</li>
        </ul>
      </section>

      <section className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Do we use 'cookies'?</h2>
        <p className="text-gray-700 mb-4">
          Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart.
        </p>
        <p className="text-gray-700 mb-4">
          You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies.
        </p>
      </section>

      <section className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Third Party Disclosure</h2>
        <p className="text-gray-700">
          We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information without your consent.
        </p>
      </section>

      <section className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contact Us</h2>
        <p className="text-gray-700 mb-4">
          If there are any questions regarding this Privacy Policy, you may contact us using the information below:
        </p>
        <ul className="text-gray-700">
          <li>Email: support@learnifyhub.in</li>
        </ul>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
