import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../context/AuthContext';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Signup = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!userName || !userEmail || !phoneNo || !password || !confirmPassword) {
      setErrorMessage('All fields are required.');
      return;
    }
    if (phoneNo.length !== 10) {
      setErrorMessage('Phone number must be 10 digits.');
      return;
    }
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      setErrorMessage(
        'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
      );
      return;
    }
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    const referralCode = localStorage.getItem('referralCode');
    setIsLoading(true);
    try {
      const response = await axiosInstance.post('auth/signup', {
        username: userName,
        email: userEmail,
        mobileNo: phoneNo,
        password: password,
        referralCode: referralCode,
      });

      if (response.status === 201) {
        setSuccessMessage(
          `Welcome, ${response.data.user.username}! Redirecting...`
        );
        setErrorMessage('');
        localStorage.setItem('token', response.data.token);
        setTimeout(() => {
          login();
          navigate(response.data.redirectUrl);
          window.scrollTo(0, 0);
        }, 1000);
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        setErrorMessage(data?.message || 'Error registering user.');
        setSuccessMessage('');
      } else {
        setErrorMessage('Network error occurred. Please try again.');
        setSuccessMessage('');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const toggleConfirmPasswordVisibility = () =>
    setConfirmPasswordVisible(!confirmPasswordVisible);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-100 via-blue-300 to-blue-500 p-4 sm:p-6">
      <form
        onSubmit={handleSignup}
        className="bg-white p-6 sm:p-10 rounded-2xl shadow-2xl max-w-md w-full space-y-6 transform transition-all duration-500 hover:shadow-xl"
      >
        <h2 className="text-2xl sm:text-3xl font-extrabold text-center text-gray-900 mb-4">
          Create an Account
        </h2>

        {successMessage && (
          <p className="text-green-500 text-center bg-green-100 border border-green-500 rounded-lg p-2">
            {successMessage}
          </p>
        )}
        {errorMessage && (
          <p className="text-red-500 text-center bg-red-100 border border-red-500 rounded-lg p-2">
            {errorMessage}
          </p>
        )}

        <div>
          <input
            type="text"
            placeholder="Full Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-400"
          />
        </div>

        <div>
          <input
            type="email"
            placeholder="Email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-400"
          />
        </div>

        <div>
          <input
            type="number"
            placeholder="Phone Number"
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-400"
          />
        </div>

        <div className="relative">
          <input
            type={passwordVisible ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-400"
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-3 flex items-center text-gray-500"
          >
            {passwordVisible ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div>

        <div className="relative">
          <input
            type={confirmPasswordVisible ? 'text' : 'password'}
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-400"
          />
          <button
            type="button"
            onClick={toggleConfirmPasswordVisibility}
            className="absolute inset-y-0 right-3 flex items-center text-gray-500"
          >
            {confirmPasswordVisible ? <FaEye /> : <FaEyeSlash />}
          </button>
        </div>

        <button
          type="submit"
          className={`w-full text-white font-semibold py-3 rounded-xl shadow-md transition duration-300 transform ${
            isLoading
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:scale-105'
          }`}
          disabled={isLoading}
        >
          {isLoading ? 'Please Wait...' : 'Sign Up'}
        </button>

        <div className="text-center text-gray-700 mt-4">
          Already have an account?{' '}
          <Link
            to="/login"
            className="text-blue-600 hover:underline font-semibold"
            onClick={() => window.scrollTo(0, 0)}
          >
            Log In
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Signup;
