import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import logo from './homeComponents/asset/logo-update.png';
import '../styles/header.css'
import axiosInstance from '../api/axiosInstance';
import Profile from './ProfileModel';

const Header = () => {
    const { isAuthenticated, logout } = useAuth();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [firstLetter, setFirstLetter] = useState('S'); // Default first letter
    const [showProfile, setShowProfile] = useState(false);

    const openProfileModal = () => setShowProfile(true);
    const closeProfileModal = () => setShowProfile(false);

    useEffect(() => {
        const checkStatus = async () => {
            try {
                const response = await axiosInstance.get('/auth/checkAuth');

                if (response.data.success) {
                    setFirstLetter((response.data.user.username).trimStart().toUpperCase()[0]);
                }
            } catch (error) {
                console.error('Error checking authentication status:', error);
            }
        };


        checkStatus();
    }, [isAuthenticated]);




    const handleLogout = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.post('auth/logout', {});
            if (response.status === 200) {
                logout();
                alert('Logout successful');
                navigate('/');
                window.scrollTo(0, 0);
            }
        } catch (error) {
            console.error('Logout error:', error);
            alert('Logout failed: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };



    return (
        <nav className="p-4 flex justify-between items-center shadow-lg sticky top-0 z-50 text-black bg-white">
            {/* Logo */}
            <div className="text-2xl font-bold">
                <Link to="/" onClick={() => {
                    setIsOpen(false)
                    window.scrollTo(0, 0);
                    }}>
                    <img src={logo} alt="Learnify Logo" className="w-48 md:w-60" />
                </Link>
            </div>

            {/* Hamburger Icon for Mobile */}
            <div className="md:hidden flex items-center">
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    aria-label="Toggle navigation"
                    className="text-black focus:outline-none"
                >
                    {isOpen ? (
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    ) : (
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    )}
                </button>
            </div>

            {/* Dropdown Menu for Mobile */}
            <div className={`fixed top-0 left-0 w-full h-full bg-white z-40 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
                <div className="p-4 flex justify-between items-center border-b">
                <Link to="/" onClick={() => {
                    setIsOpen(false)
                    window.scrollTo(0, 0);
                    }}>
                        <img src={logo} alt="Learnify Logo" className="w-48" />
                    </Link>
                    <button onClick={() => setIsOpen(false)} className="text-black focus:outline-none">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="flex flex-col items-start p-4 space-y-4">

                    <Link to="/" className="text-black text-lg" onClick={() => {
                        setIsOpen(false)
                        window.scrollTo(0, 0);
                        }}>Home</Link>
                    {/* <Link to="/job-guarantee" className="text-black text-lg" onClick={() => setIsOpen(false)}>Job Guarantee</Link>
                    <Link to="/hire" className="text-black text-lg" onClick={() => setIsOpen(false)}>Hire From Us</Link>
                    <Link to="/apply" className="bg-blue-600 text-white px-4 py-2 rounded-md" onClick={() => setIsOpen(false)}>Apply Now</Link> */}
                    {isAuthenticated ? (
                        <>
                            <div className='text-black text-lg' onClick={() => {
                                setIsOpen(false);
                                openProfileModal();
                            }} >
                                Profile
                            </div>
                            <button
                                onClick={handleLogout}
                                disabled={loading}
                                className="bg-red-600 text-white px-5 py-2 rounded-md w-full"
                            >
                                {loading ? 'Logging out...' : 'Logout'}
                            </button>
                        </>
                    ) : (
                        <Link to="/login" className="rounded-md  bg-black text-white px-2 py-1 transition duration-300 ease-in-out border-2 border-black text-lg" onClick={() => {
                            setIsOpen(false)
                            window.scrollTo(0, 0);
                            }}>Login</Link>
                    )}
                </div>
            </div>

            {/* Desktop Links (hidden on mobile) */}
            <div className="hidden md:flex space-x-4 items-center">
                {/* <Link to="/" className="text-black">Home</Link> */}
                {/* <Link to="/job-guarantee" className="text-black">Job Guarantee</Link> */}
                {/* <Link to="/hire" className="text-black">Hire From Us</Link> */}
                {/* <Link to="/apply" className="bg-blue-600 text-white px-4 py-2 rounded-md">Apply Now</Link> */}
                {isAuthenticated ? (
                    <div className="relative right-5">
                        <div
                            className="user-icon-navbar cursor-pointer"
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                        >
                            <span>{firstLetter}</span>
                        </div>

                        {/* Dropdown Menu */}
                        {dropdownOpen && (
                            <div className="dropdown-container-navBar">
                                <div
                                    className="dropdown-link-navBar"
                                    onClick={() => {
                                        setDropdownOpen(false);
                                        openProfileModal();
                                    }}
                                >
                                    Profile
                                </div>
                                <button
                                    onClick={() => {
                                        setDropdownOpen(false);
                                        handleLogout();
                                    }}
                                    className="dropdown-button-navBar"
                                    disabled={loading}
                                >
                                    {loading ? 'Logging out...' : 'Logout'}
                                </button>
                            </div>
                        )}

                    </div>

                ) : (
                    <Link to="/login" onClick={() => {setIsOpen(false) 
                    window.scrollTo(0, 0);}} className="px-4 py-1 rounded-md hover:bg-black hover:text-white transition duration-300 ease-in-out border-2 border-black font-medium flex items-center text-black">
                        Login
                    </Link>
                )}
            </div>
            {showProfile && <Profile onClose={closeProfileModal} />}
        </nav>
    );
};

export default Header;
