import React from 'react'
import './style/placementAssistance.css'
import icon1 from './asset/cardIcons/icon7.jpg';
import icon2 from './asset/cardIcons/icon8.jpg';
import icon3 from './asset/cardIcons/icon9.jpg';
import icon4 from './asset/cardIcons/icon10.jpg';
import icon5 from './asset/cardIcons/icon11.jpg';
import Card from './Card'

const PlacementAssistance = () => {
    const cardData = [
        {
            icon: icon1,
            title: "",
            description: "1:1 Interview preparation (Mock Interviews) before technical interview rounds with our hiring partners",
            highlight: "",
        },
        {
            icon: icon2,
            title: "",
            description: "Soft-Skills training coupled with pre-training and post-training assessments",
            highlight: "",
        },
        {
            icon: icon3,
            title: "",
            description: "Series of mock assessments and detailed interview prep sprints to ace top tech jobs",
            highlight: "",
        },
        {
            icon: icon4,
            title: "",
            description: "Expert guidance to get your profile ready (Github, LinkedIn, Resume)",
            highlight: "",
        },
        {
            icon: icon5,
            title: "",
            description: "Connect with a vibrant community of learners and industry professionals to expand your network",
            highlight: "",
        },
    ];

    return (
        <div className='container-placement-assistance rubik-hero-section bg-white'>
            <div>
                <h2 className='text-4xl font-semibold placement-assistance-header manrope-hero-section'>Personalised Placement Assistance - <span className='span-placement-assistance'>With Assured Referrals</span></h2>
                <p className='para-placement-assistance'>Get access to Learnify’s Exclusive Career Services that will equip you to use your learnings and skills to land your next job.</p>
            </div>
            <div className="card-section-placement-assistance">
                {cardData.map((card, index) => (
                    <Card
                        key={index}
                        icon={card.icon}
                        title={card.title}
                        description={card.description}
                        highlight={card.highlight}
                    />
                ))}
            </div>
        </div>
    );
};

export default PlacementAssistance
