import React, { useState } from 'react';
import './style/CurriculumSection.css';
import { useAuth } from '../../context/AuthContext';

const svgIcon = { fontSize: "17px" };

// Example topics and content data
const topics = [
  {
    title: 'Programming Foundations',
    duration: '4 to 8 weeks',
    description: 'Kickstart your Programmer journey by creating a solid foundation of programming and language skills. Start using standard industry developer technologies.',
    details: {
      fundamentals: ['Loops', 'Conditionals', 'Arrays', 'Objects', 'Strings', 'Data Types', 'Libraries'],
      essentials: ['HTTP', 'Rest API', 'Deployment', 'Linux', 'GIT', 'SQL']
    }
  },
  {
    title: 'Data Structures and Algorithms',
    duration: '20 weeks',
    dsa: [
      {
        dsaTitle: 'DSA Basics',
        dsaDescription: ['Build problem solving skills with the commonly used Data Structures. Learn the basics of Space and Time complexity which drive optimization.', 'Programming Essentials', 'Develop logic building skills by solving simple math, recursion, implementation problems', 'Learn Time & Space complexity', 'Get introduced to data structures & traversals - Linkedlist & Trees'],
        dsaTech: ['Logic Building', 'Collections', 'Space and Time Complexity']
      },
      {
        dsaTitle: 'Arrays & Strings',
        dsaDescription: ['Learn various patterns of problem solving in arrays & strings and apply them in a wide variety of problems.', 'Learn the art of debugging that is critical for your timed interviews.'],
        dsaTech: ['Matrix', 'Two Pointers', 'Sliding Window', 'Sorting', 'Prefix Sum', 'Bit Manipulation', 'Binary Search']
      },
      {
        dsaTitle: 'Stack, Queue and Linked List',
        dsaDescription: ['Learn Linked List implementation and applications', 'Apply Queue, Stack to solve real world problems'],
        dsaTech: ['Data Structure Application', 'Linked List', 'Stack', 'Queue']
      },
      {
        dsaTitle: 'Hash, Trees',
        dsaDescription: ['Apply Hash to solve real world problems', 'Learn BFS/DFS to solve multiple Tree problems effectively'],
        dsaTech: ['Data Structure Application', 'Hash', 'Binary Tree', 'Binary Search Tree', 'BFS', 'DFS', 'Recursion']
      },
      {
        dsaTitle: 'Heap, Graphs, Greedy and Backtracking',
        dsaDescription: [`Deep dive into graph problem patterns and implement common algorithms like Prim’s, Kruskal’s and Dijkstra's`, 'Learn common problem solving approaches like greedy & backtracking'],
        dsaTech: ['Graph BFS', 'Graph DFS', 'Heaps', 'Shortest Path', 'Graph Partitioning', 'Greedy Method', 'Advanced recursion and backtracking', 'Topological Sort']
      },
      {
        dsaTitle: 'Dynamic Programming and Advanced DS',
        dsaDescription: ['Learn the art of DP and practice problems using Memoization and Tabulation', 'Get introduced to advanced DS like Trie, Segment Trees and solve related problems'],
        dsaTech: ['DP Memoization', 'DP Tabulations', 'Knapsack', 'Spanning Tree', 'Trie', 'Segment Tree']
      },
    ]
  },
  {
    title: 'Tech Stack Specialization & Work-like Projects',
    duration: '16 to 28 weeks',
    description: 'Gain hands-on experience with real-world projects and learn to work in teams with industry-relevant technologies.',
    projects: [{
      proTitle: "Build an AirBnB-like static frontend to master HTML, CSS & more",
      proDescription: "Build a responsive front end for QTrip, an AirBnB-like travel planning website, and learn HTML, CSS, Bootstrap and more",
      proTechnology: ['HTML', 'CSS', 'Flexbox', 'Bootstrap']
    },
    {
      proTitle: "Build an AirBnB-like dynamic frontend to master Rest API, Routing, Filters & more",
      proDescription: "Add life to the web pages of QTrip by making them dynamic and by integrating with a backend and 3rd party plugins.",
      proTechnology: ['API Calls', 'Routing', 'Filters', 'Data Persistence', '3rd Party Libraries']
    },
    {
      proTitle: "Build a React powered frontend for an Amazon-like shopping app",
      proDescription: "Build the frontend for QKart - an online ecommerce store to learn React, and create a rich shopping experience.",
      proTechnology: ['Components', 'Component lifecycle', 'Component state management', 'Component styling', 'Props', 'Routing', 'React Hooks', 'Material UI']
    },
    {
      proTitle: "Build the frontend for a Spotify like app",
      proDescription: "Apply your learnings in React to build an independent project, the frontend of QTify - an audio-streaming platform like Spotify.",
      proTechnology: ['Figma', 'Material UI', 'React', 'REST API', 'Deployment']
    },
    {
      proTitle: "Build a Node JS powered backend for a Flipkart-like app",
      proDescription: "Build a scalable NodeJS backend for QKart using the Express.js framework",
      proTechnology: ['Express JS', 'Node JS', 'MongoDB', 'REST API']
    },
    {
      proTitle: "Build the backend for a Netflix-like OTT app",
      proDescription: "Apply your learnings from QKart Backend to build your second independent project using Express and Node JS, the backend for XFlix - a video streaming platform like Netflix.",
      proTechnology: ['Express JS', 'Node JS', 'MongoDB', 'REST API', 'Deployment']
    },
    {
      proTitle: "Build the backend for a stock portfolio analyser like Zerodha",
      proDescription: "Start your backend development journey by building QMoney, a visual stock portfolio analyser.",
      proTechnology: ['OOP', 'JSON', 'Consuming REST API', 'Refactoring', 'Gradle', 'Exception Handling', 'JUnit', 'Jackson']
    },
    {
      proTitle: "Apply your learnings to build a Giphy like app",
      proDescription: "Apply your learnings from the entire program so far to build a fully functional Java backend for XMeme(a page where users can post and view Memes).",
      proTechnology: ['Backend', 'REST API', 'SpringBoot', 'MongoDB']
    },
    {
      proTitle: "Build the backend for a Netflix-like OTT app",
      proDescription: "Apply your learnings from QKart Backend to build your second independent project using Express and Node JS, the backend for XFlix - a video streaming platform like Netflix.",
      proTechnology: ['Express JS', 'Node JS', 'MongoDB', 'REST API', 'Deployment']
    },
    ]
  },
  {
    title: 'Tech Stack Specialisation (Advanced)',
    duration: '4 to 8 weeks',
    dsa: [
      {
        dsaTitle: 'Frontend Advanced',
        dsaDescription: ['Master essential concepts required to be a strong FE developer through a series of hands-on workshops, activities and projects.', 'Learn concepts like Caching, Security, Website performance optimization, Redux State Management, Server Side Rendering using NextJS and others'],
        dsaTech: ['Redux', 'NextJS', 'CDN', 'Advanced CSS', 'SEO']
      },
      {
        dsaTitle: 'Backend Advanced',
        dsaDescription: ['Work on a Swiggy like application and improve its performance', 'Make the Java backend for QEats, scale-able', 'Build advanced search features, solve for performance bottlenecks and improve REST API performance'],
        dsaTech: ['Caching', 'Redis', 'JMeter', 'Multi-threading']
      },
    ]
  },
  {
    title: 'System Design',
    duration: '8 weeks',
    projects: [
      {
        proTitle: "Build a Hackerrank-like App to master Basics of Low Level Design",
        proDescription: "Build QContest, a hackerrank-like contest platform by applying your Core Java and Low Level design skills",
        proTechnology: ['SOLID Principles', 'Object Oriented Design', 'Object Modeling', 'Clean Architecture', 'Design Patterns']
      },
      {
        proTitle: "Create an Spotify like application by applying machine coding techniques",
        proDescription: "Build the JukeBox application, which can play songs from a playlist, using clean architecture",
        proTechnology: ['OOP', 'Machine Coding']
      },
      {
        proTitle: "HLD Foundations",
        proDescription: "Understand essential components/concepts of a modern tech stack used universally by internet companies with bonus hands-on exercises",
        proTechnology: ['Architecture of Web Applications', 'Docker', 'Kafka', 'Micro-Services', 'CI/CD', 'Cloud', 'CAP Theorem', 'SQL/NoSQL', 'Scalability', 'Load Balancing', 'Partitioning', 'Caching']
      },
    ]
  },
  {
    title: 'Interview Blitz',
    duration: '4 weeks',
    dsa: [
      {
        dsaTitle: 'Interview Prep',
        dsaDescription: ['Practice multiple timed mock assessments', 'Perfect the art of cracking DSA interviews through multiple DSA Blitz sessions', 'Prepare to defend every single skill keyword added to your resume'],
        dsaTech: ['DSA Mocks', 'DSA Blitz', 'P2P Interviews', 'Take home Prep', 'Specialization Specific Prep']
      },
    ]
  }
];

const CurriculumSection = () => {
  const { setShowModal } = useAuth();
  const [selectedTopic, setSelectedTopic] = useState(topics[0]);
  const [activeIndex, setActiveIndex] = useState(null); // For mobile accordion

  // Toggle accordion for mobile
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


  return (
    <div className='rubik-hero-section curriculam-section-container' >
      <div class="curriculum-heading-container bg-gradient-to-r from-blue-100 to-cyan-100">
        <h2 class="curriculum-heading manrope-hero-section">Curriculum To Crack Dream Jobs (6 To 15 LPA) In Top Product-Based Companies</h2>
      </div>
      <div className="curriculum-container bg-gradient-to-r from-blue-100 to-cyan-100">
        {/* Desktop Version: Two Column Layout */}
        <div className="topics-list desktop-view">
          {topics.map((topic, index) => (
            <div
              key={index}
              onClick={() => setSelectedTopic(topic)}
              className={`topic-item ${selectedTopic.title === topic.title ? 'active' : ''}`}
            >
              <h3 className='font-extrabold'>{topic.title}</h3>
              <p className='font-medium text-lightGrayDark'>{topic.duration}</p>
            </div>
          ))}
        </div>

        <div className="topic-content fade-in desktop-view ">
          <h2 className='font-bold'>{selectedTopic.title}</h2>
          <p className='font-medium text-lightGrayDark mb-4'>{selectedTopic.duration}</p>
          {selectedTopic?.description?.length > 0 && (<p className='m-4'>{selectedTopic.description}</p>)}


          {selectedTopic?.projects?.length > 0 && (
            selectedTopic.projects.map((proj, index) => (
              <div key={index} className='bg-gradient-to-r from-blue-100 to-cyan-100 p-6 mb-4 rounded-3xl'>
                <h2 className='font-extrabold text-lightGrayDark'>{proj.proTitle}</h2>
                <ul className='lg:list-disc p-4'>
                  <li>
                    <p>{proj.proDescription}</p>
                  </li>
                </ul>
                <div className='pill-container'>
                  {proj.proTechnology.map((tech, index) => (
                    <span key={index} className="pill border-2 border-lightGreenDark font-bold text-lightGrayDark">{tech}</span>
                  ))}
                </div>
              </div>
            ))
          )}


          {selectedTopic?.dsa?.length > 0 && (
            selectedTopic.dsa.map((data, index) => (
              <div key={index} className='bg-gradient-to-r from-blue-100 to-cyan-100 p-6 mb-4 rounded-3xl'>
                <h2 className='font-extrabold text-lightGrayDark'>{data.dsaTitle}</h2>
                <ul className='lg:list-disc p-4'>
                  {data.dsaDescription.map((descrip, index) => (
                    <li>
                      <p>{descrip}</p>
                    </li>
                  ))}
                </ul>
                <div className='pill-container'>
                  {data.dsaTech.map((tech, index) => (
                    <span key={index} className="pill border-2 border-lightGreenDark font-bold text-lightGrayDark">{tech}</span>
                  ))}
                </div>
              </div>
            ))
          )}



          {selectedTopic?.details?.fundamentals?.length > 0 && (
            <div className="fundamentals bg-gradient-to-r from-blue-100 to-cyan-100 p-6 mb-4 rounded-3xl">
              <h4>Language Fundamentals</h4>
              <div className="pill-container">
                {selectedTopic.details.fundamentals.map((item, index) => (
                  <span key={index} className="pill border-2 border-lightGreenDark font-bold text-lightGrayDark">{item}</span>
                ))}
              </div>
            </div>
          )}

          {selectedTopic?.details?.essentials?.length > 0 && (
            <div className="essentials bg-gradient-to-r from-blue-100 to-cyan-100  p-6 mb-4 rounded-3xl">
              <h4>Developer Essentials</h4>
              <div className="pill-container">
                {selectedTopic.details.essentials.map((item, index) => (
                  <span key={index} className="pill  border-2 border-gray-300  bg-lightGreenMe font-bold text-lightGrayDark">{item}</span>
                ))}
              </div>
            </div>
          )}


        </div>
        {/* Mobile Accordion */}
        <div className="mobile-view mb-5 pb-5">
          {topics.map((topic, index) => (
            <div key={index} className="accordion-item">
              <div
                className={`accordion-title ${activeIndex === index ? 'active' : ''} flex items-center justify-between`}
                onClick={() => toggleAccordion(index)}
              >
                <div>
                  <h3 className='font-extrabold'>{topic.title}</h3>
                  <p className='font-medium text-lightGrayDark'>{topic.duration}</p>
                </div>
                <div className='mobile-view  svgIcon'><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="text-white svg-inline--fa fa-chevron-right w-[10px] rotate-90 transition-all " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path></svg></div>
              </div>
              <div className={`accordion-content ${activeIndex === index ? 'open' : ''}`}>
                {topic?.description?.length > 0 && (<p className='m-4 ml-2 mr-2 text-sm'>{topic.description}</p>)}

                {topic?.projects?.length > 0 && (
                  topic.projects.map((proj, index) => (
                    <div key={index} className='bg-gradient-to-r from-blue-100 to-cyan-100 pt-4 pl-3 pr-3 pb-4 mb-4 rounded-3xl'>
                      <h2 className='font-bold text-lightGrayDark'>{proj.proTitle}</h2>
                      <ul className='lg:list-disc p-4 list-disc'>
                        <li>
                          <p className='text-xs'>{proj.proDescription}</p>
                        </li>
                      </ul>
                      <div className='pill-container'>
                        {proj.proTechnology.map((tech, index) => (
                          <span key={index} className="pill border-2 border-lightGreenDark font-bold text-lightGrayDark">{tech}</span>
                        ))}
                      </div>
                    </div>
                  ))
                )}


                {topic?.dsa?.length > 0 && (
                  topic.dsa.map((data, index) => (
                    <div key={index} className='bg-gradient-to-r from-blue-100 to-cyan-100 pt-4 pl-2 pr-2 pb-4 mb-4 mt-2 rounded-3xl'>
                      <h2 className='font-extrabold text-lightGrayDark'>{data.dsaTitle}</h2>
                      <ul className='lg:list-disc pt-4 text-xs list-disc pl-4'>
                        {data.dsaDescription.map((descrip, index) => (
                          <li>
                            <p>{descrip}</p>
                          </li>
                        ))}
                      </ul>
                      <div className='pill-container'>
                        {data.dsaTech.map((tech, index) => (
                          <span key={index} className="pill border-2 border-lightGreenDark font-bold text-lightGrayDark">{tech}</span>
                        ))}
                      </div>
                    </div>
                  ))
                )}

                {topic?.details?.fundamentals?.length > 0 && (
                  <div className="fundamentals bg-gradient-to-r from-blue-100 to-cyan-100">
                    <h4 className='font-bold text-lightGrayDark'>Language Fundamentals</h4>
                    <div className="pill-container">
                      {topic.details.fundamentals.map((item, idx) => (
                        <span key={idx} className="pill text-lightGrayDark">{item}</span>
                      ))}
                    </div>
                  </div>
                )}

                {topic?.details?.essentials?.length > 0 && (
                  <div className="essentials bg-gradient-to-r from-blue-100 to-cyan-100 mt-4">
                    <h4 className='font-bold text-lightGrayDark'>Developer Essentials</h4>
                    <div className="pill-container">
                      {topic.details.essentials.map((item, idx) => (
                        <span key={idx} className="pill essential text-lightGrayDark">{item}</span>
                      ))}
                    </div>
                  </div>
                )}


              </div>
            </div>
          ))}
        </div>
      </div>
      <button
        type="submit"
        className="enrollIcon"
        onClick={()=>{setShowModal(true)}}
      >
        Contact Us
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right ml-2 size-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" style={svgIcon}><path fill="currentColor" d="M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z"></path></svg>
      </button>
    </div>
  );
};

export default CurriculumSection;
