import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import logo from '../components/homeComponents/asset/logo.png'
import { useNavigate } from 'react-router-dom';

function CheckoutPage() {
  const navigate = useNavigate();
  const originalPrice = 74999;
  const discountedPrice = 29999;
  const savings = originalPrice - discountedPrice;

  // eslint-disable-next-line no-unused-vars
  const [recentEnrollments, setRecentEnrollments] = useState(40); // Recent enrollments count
  const [isProcessing,setIsProcessing] = useState(false)

  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const response = await axiosInstance.get('/auth/checkAuth');

        if (response.data.success) {
          setCurrentUser(response.data.user)
        } 
      } catch (error) {
        console.error('Error checking authentication status:', error);
      }
    };


    checkStatus();
  }, []);


  // const handleEnrollNow = () => {
  //   // Logic to handle enrollment and redirect to payment gateway
  //   alert('Redirecting to payment gateway...');
  // };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js');
  }, []);

  const initiatePayment = async () => {
    if (isProcessing) return; 
    setIsProcessing(true);
    try {
      // Step 1: Create Razorpay Order
      const orderResponse = await axiosInstance.post('/payment/create-order');

      const { id: order_id, amount, currency } = orderResponse.data;

      // Step 2: Open Razorpay Checkout
      const options = {
        key: process.env.RAZARPAY_KEY_ID, // Add this key in frontend .env
        amount: amount,
        currency: currency,
        name: 'Learnify',
        description: 'Full Stack Web Development Course',
        image: logo, // Optional
        order_id: order_id,
        handler: async function (response) {
          // Step 3: Verify Payment
          const verificationResponse = await axiosInstance.post(
            '/payment/verify-payment',
            {
              order_id,
              payment_id: response.razorpay_payment_id,
              signature: response.razorpay_signature,
              userId : currentUser._id
            }
          );

          if (verificationResponse.data.success) {
            alert('Payment Successful!');
            navigate('/')
            window.scrollTo(0, 0);
          } else {
            alert('Payment Verification Failed!');
          }
        },
        prefill: {
          name: currentUser.username,
          email: currentUser.email,
          contact: currentUser.mobileNo,
        },
        notes: {
          address: 'Learnify Corporate Office',
        },
        theme: {
          color: '#528FF0',
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();

      rzp.on('payment.failed', function (response) {
        alert(response.error.description);
      });
    } catch (error) {
      console.error('Error during payment:', error.message);
    } finally {
      setIsProcessing(false);
    }
  };




  return (
    <div style={styles.container}>
      {/* Heading and Subheading */}
      <h1 style={styles.heading} className='manrope-hero-section'>Join Learnify’s Ultimate Full-Stack Developer Program</h1>
      <p style={styles.subheading} className='manrope-hero-section' >Unlock your potential with industry-driven skills and expert mentorship</p>

      {/* Price Section */}
      <div style={styles.priceSection} className='manrope-hero-section'>
        <p style={styles.originalPrice}>₹{originalPrice}</p>
        <p style={styles.discountedPrice}>₹{discountedPrice}</p>
        <p style={styles.savings}>You Save: ₹{savings}!</p>
      </div>

      {/* Social Proof - Real-time Enrollment */}
      <div style={styles.enrollmentNotification} className='rubik-hero-section'>
        <p>{recentEnrollments}+ students enrolled this week!</p>
      </div>

      {/* Value Proposition */}
      <div style={styles.valueSection}>
        <h2 className='manrope-hero-section'>What You’ll Get:</h2>
        <ul style={styles.valueList} className='rubik-hero-section'>
          <li>✅ Lifetime access to all course materials</li>
          <li>✅ Hands-on projects & real-world applications</li>
          <li>✅ Weekly live sessions with industry experts</li>
          <li>✅ Certificate recognized by top companies</li>
          <li>✅ Access to our exclusive developer community</li>
        </ul>
      </div>

      {/* Bonuses */}
      <div style={styles.bonusSection}>
        <h2 className='manrope-hero-section'>Exclusive Bonuses (Enroll Today):</h2>
        <ul style={styles.bonusList} className='rubik-hero-section'>
          <li>1-on-1 Career Coaching Session (₹9,999 Value)</li>
          <li>Private Job Board Access for 1 Year (₹5,999 Value)</li>
          <li>Resume & LinkedIn Profile Review (₹4,999 Value)</li>
        </ul>
      </div>

      {/* Referral Program */}
      {/* <div style={styles.referralSection} className='rubik-hero-section'>
        <h3>Refer & Earn ₹2000!</h3>
        <p>Invite your friends to Learnify and earn ₹2000 for each successful referral.</p>
      </div> */}

      {/* Customer Testimonials */}
      <div style={styles.testimonialsSection}>
        <h2>See What Our Students Are Saying:</h2>
        <blockquote>"Learnify transformed my career. Within 6 months, I landed a new job!" — Priya K.</blockquote>
        <blockquote>"Before Learnify, I was stuck in a dead-end job. After the course, I got a position at a top tech company!" — Sandeep Singh.</blockquote>
      </div>

      {/* Call-to-Action Button */}
      <button style={styles.enrollButton} onClick={initiatePayment} disabled={isProcessing} className='manrope-hero-section'>{isProcessing ? 'Processing...' : 'Enroll Now - Secure Checkout'}</button>

      {/* Footer with Trust Badges */}
      <div style={styles.footer} className='rubik-hero-section'>
        <p> Quality Assurance |  SSL Secured |  Secure and encrypted </p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    padding: '40px 20px',
    backgroundColor: '#f7f9fc',
    color: '#333',
    textAlign: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  heading: {
    fontSize: '36px',
    color: '#333',
    marginBottom: '10px',
  },
  subheading: {
    fontSize: '20px',
    color: '#555',
    marginBottom: '30px',
  },
  priceSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
    marginBottom: '20px',
  },
  originalPrice: {
    fontSize: '24px',
    textDecoration: 'line-through',
    color: '#888',
  },
  discountedPrice: {
    fontSize: '36px',
    color: '#2a9d8f',
    fontWeight: 'bold',
  },
  savings: {
    fontSize: '18px',
    color: '#2a9d8f',
    fontWeight: 'bold',
  },
  enrollmentNotification: {
    marginBottom: '20px',
    fontSize: '18px',
    color: '#28a745',
  },
  valueSection: {
    textAlign: 'left',
    padding: '20px',
    marginBottom: '30px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
  },
  valueList: {
    listStyle: 'none',
    padding: '0',
    fontSize: '18px',
    color: '#444',
  },
  bonusSection: {
    textAlign: 'left',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    marginBottom: '30px',
  },
  bonusList: {
    listStyle: 'none',
    padding: '0',
    fontSize: '18px',
    color: '#444',
  },
  referralSection: {
    backgroundColor: '#fff8e1',
    padding: '15px 20px',
    borderRadius: '8px',
    marginBottom: '20px',
    fontSize: '18px',
    color: '#333',
  },
  testimonialsSection: {
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    marginBottom: '30px',
    fontStyle: 'italic',
    color: '#444',
  },
  enrollButton: {
    backgroundColor: '#2a9d8f',
    color: '#fff',
    fontSize: '20px',
    padding: '15px 30px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  footer: {
    marginTop: '40px',
    fontSize: '14px',
    color: '#777',
  },
};

export default CheckoutPage;
