import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles/login.css';
import axiosInstance from '../api/axiosInstance';

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');
    setIsLoading(true);

    try {
      const response = await axiosInstance.post('auth/login', {
        userEmail,
        password,
      });

      localStorage.setItem('token', response.data.token);

      if (response.status === 200) {
        setSuccessMessage('Login successful! Redirecting...');
        login();
        setTimeout(() => {
          navigate(response.data.redirectUrl);
          window.scrollTo(0, 0);
        }, 1500);
      } else {
        setErrorMessage(response.data.message || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Unable to log in. Check your credentials.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-100 via-blue-300 to-blue-500 p-6">
      <form
        onSubmit={handleLogin}
        className="bg-white p-8 sm:p-10 rounded-xl shadow-lg max-w-md w-full space-y-6 transform transition duration-500 hover:shadow-xl"
      >
        <h2 className="text-2xl sm:text-3xl font-extrabold text-center text-blue-700 mb-4">
          Welcome Back to Learnify
        </h2>

        {/* Success & Error Messages */}
        {successMessage && (
          <div className="bg-green-100 text-green-700 p-3 rounded-lg text-center text-sm sm:text-base font-semibold">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="bg-red-100 text-red-700 p-3 rounded-lg text-center text-sm sm:text-base font-semibold">
            {errorMessage}
          </div>
        )}

        {/* Input Fields */}
        <div className="space-y-4">
          <input
            type="email"
            placeholder="Email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 placeholder-gray-500"
          />

          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 placeholder-gray-500"
          />
        </div>

        {/* Login Button */}
        <button
          type="submit"
          className={`w-full text-white font-semibold py-3 rounded-lg shadow-md transition duration-300 transform ${
            isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 hover:shadow-lg hover:scale-105'
          }`}
          disabled={isLoading}
        >
          {isLoading ? 'Logging in...' : 'Login'}
        </button>

        {/* Links */}
        <div className="text-center text-gray-600 mt-4">
          Don’t have an account?{' '}
          <Link
            to="/signup"
            className="text-blue-600 hover:underline font-semibold"
            onClick={() => window.scrollTo(0, 0)}
          >
            Sign Up
          </Link>
        </div>

        <div className="text-center text-gray-600 mt-2">
          <Link to="/forgot-password" className="text-blue-600 hover:underline font-semibold">
            Forgot Password?
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
