import React from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
  return (
    <div className="bg-gray-50 min-h-screen py-12">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg px-8 py-10">
        <h1 className="text-4xl font-bold text-center text-blue-600 mb-12 border-b-4 border-blue-500 pb-4">
          Terms & Conditions
        </h1>

        <section className="mb-10">
          <h2 className="text-2xl font-semibold text-blue-500">1. Introduction</h2>
          <p className="mt-4 text-lg text-gray-700 leading-relaxed">
            Welcome to Learnify, an online learning platform dedicated to providing quality educational resources and
            training services in the fields of web development, data structures, and various other technical domains. By
            accessing or using Learnify’s website, services, and content, you agree to comply with these Terms and
            Conditions. If you do not agree with any of these terms, you are not authorized to use our services.
          </p>
        </section>

        <section className="mb-10">
          <h2 className="text-2xl font-semibold text-blue-500">2. Services Offered</h2>
          <p className="mt-4 text-lg text-gray-700 leading-relaxed">
            Learnify offers a range of online courses, training programs, and educational resources to individuals
            seeking to enhance their skills and knowledge. Our services include, but are not limited to:
          </p>
          <ul className="list-disc ml-8 mt-4 text-lg text-gray-700 space-y-2">
            <li>Live and recorded courses on various technical topics such as full-stack web development, data structures and algorithms, machine learning, and more.</li>
            <li>Access to a community of learners for peer support and collaboration.</li>
            <li>Study materials, assignments, and assessments aimed at reinforcing the learning experience.</li>
          </ul>
          <p className="mt-4 text-lg text-gray-700 leading-relaxed">
            We reserve the right to modify, suspend, or discontinue any of our services at our discretion. Any such
            changes will be communicated to you in a timely manner.
          </p>
        </section>

        <section className="mb-10">
          <h2 className="text-2xl font-semibold text-blue-500">3. Career Support and Referrals</h2>
          <p className="mt-4 text-lg text-gray-700 leading-relaxed">
            At Learnify, we are dedicated to helping you kickstart and advance your career by providing top-notch
            educational materials, expert instruction, and skill-building tailored to the demands of the job market.
            While securing a job ultimately depends on various factors, we go the extra mile to support you by offering:
          </p>
          <ul className="list-disc ml-8 mt-4 text-lg text-gray-700 space-y-2">
            <li>Assured referrals to our partner networks and industry connections.</li>
            <li>Comprehensive guidance on resume building, interview preparation, and job applications.</li>
            <li>Opportunities to showcase your skills through real-world projects and practical assessments.</li>
            <li>Access to a vibrant community of professionals and peers for networking and collaboration.</li>
          </ul>
          <p className="mt-4 text-lg text-gray-700 leading-relaxed">
            We are committed to empowering you with the resources, connections, and confidence you need to pursue and
            secure opportunities in your desired field. Your success is our priority, and we are here to support you
            every step of the way.
          </p>
        </section>

        <section className="mb-10">
          <h2 className="text-2xl font-semibold text-blue-500">4. Privacy Policy</h2>
          <p className="mt-4 text-lg text-gray-700 leading-relaxed">
            Learnify is committed to protecting your privacy and handling your personal information with the utmost
            care. To understand how we collect, use, and protect your personal data, please review our{' '}
            <Link to="/privacypolicy" className="text-blue-500 hover:underline">
              Privacy Policy
            </Link>.
          </p>
        </section>

        <section className="mb-10">
          <h2 className="text-2xl font-semibold text-blue-500">5. Account Responsibilities</h2>
          <p className="mt-4 text-lg text-gray-700 leading-relaxed">
            By creating an account with Learnify, you are responsible for maintaining the confidentiality of your login
            credentials and for all activities that occur under your account. You agree to immediately notify Learnify
            of any unauthorized use of your account. Learnify reserves the right to suspend or terminate accounts that
            violate our terms or engage in fraudulent activity.
          </p>
        </section>

        <section className="mb-10">
          <h2 className="text-2xl font-semibold text-blue-500">6. Payments and Refunds</h2>
          <p className="mt-4 text-lg text-gray-700 leading-relaxed">
            All payments for Learnify’s courses and services must be made exclusively through the payment methods
            available on our official website, ensuring a secure and transparent transaction process. Please be aware
            that Learnify does not accept payments through any other platforms, third-party vendors, or offline
            methods. This policy is in place to safeguard our customers and maintain the integrity of our services.
            Additionally, course fees are strictly non-refundable under any circumstances. We advise all users to
            review the payment details carefully before completing the transaction.
          </p>
        </section>

        <section className="mb-10">
          <h2 className="text-2xl font-semibold text-blue-500">7. Contact Us</h2>
          <p className="mt-4 text-lg text-gray-700 leading-relaxed">
            If you have any questions or concerns regarding these Terms and Conditions or any other aspects of our
            services, please feel free to reach out to us. We are here to assist you and address any inquiries you may
            have:
          </p>
          <ul className="list-disc ml-8 mt-4 text-lg text-gray-700 space-y-2">
            <li>Email: <a href="mailto:support@learnifyhub.in" className="text-blue-500 hover:underline">support@learnifyhub.in</a></li>
          </ul>
        </section>

        <section className="mb-10">
          <h2 className="text-2xl font-semibold text-blue-500">8. Governing Law</h2>
          <p className="mt-4 text-lg text-gray-700 leading-relaxed">
            These Terms and Conditions shall be governed by and construed in accordance with the laws of India. Any
            disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of
            the courts in India.
          </p>
        </section>

        <section className="mb-10">
          <h2 className="text-2xl font-semibold text-blue-500">9. Modifications to Terms</h2>
          <p className="mt-4 text-lg text-gray-700 leading-relaxed">
            Learnify reserves the right to modify or update these Terms and Conditions at any time. We will notify you
            of any changes by posting the updated terms on this page. It is your responsibility to review these Terms
            periodically for any changes. By continuing to use our services, you accept any updated terms.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
