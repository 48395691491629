import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import './style/comparision.css';
import techguy2 from '../asset/techGuy2_2.png'
import { useAuth } from '../../context/AuthContext';

const Comparision = () => {
  const { setShowModal } = useAuth();
  const tableData = [
    { feature: 'Real work experience', videoCourses: false, bootcamps: false, crio: true },
    { feature: 'True, project-based learning', videoCourses: false, bootcamps: false, crio: true },
    { feature: 'Live sessions & mentorship', videoCourses: false, bootcamps: true, crio: true },
    { feature: 'Job-ready portfolio', videoCourses: false, bootcamps: false, crio: true },
    { feature: 'Externship with top companies', videoCourses: false, bootcamps: false, crio: true },
    { feature: 'Career guidance', videoCourses: false, bootcamps: true, crio: true },
    { feature: 'Assured Referrals', videoCourses: false, bootcamps: false, crio: true }
  ];

  return (
    <div className="comparison-container rubik-hero-section">
      <div className='text-center'>
        <h2 className='comparison-header manrope-hero-section'>
          Gain Real Work Experience And Hands-On, Project-Based Learning With Learnify
        </h2>
        <p className='mt-10 comparision-para'>
          The best way to master a skill is by applying it in real-world scenarios. At Learnify, we go a step further by providing you with real work experience through hands-on, project-based learning. Instead of just watching tutorials, you'll dive into building real products, just like a professional engineer.
        </p>
        <p className='mt-6 comparision-para'>
          Just like a real professional advancing their career in a leading product company.
        </p>
      </div>
      <div>
        <img src={techguy2} alt="" className='tech-guy-2-comparision' />
      </div>
      <div className='comparsion-table-container'>
        <table style={styles.table} className='comparison-table'>
          <thead>
            <tr>
              <th className='comparision-table-header'></th>
              <th className='comparision-table-header' >Video Courses</th>
              <th className='comparision-table-header'>Bootcamps</th>
              <th className='comparision-table-header'>Learnify</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index} style={index % 2 === 0 ? styles.rowEven : styles.rowOdd}>
                <td className='comparision-table-cell comparision-feature-cell' style={styles.featureStyle}>{row.feature}</td>

                <td className='comparision-table-cell'>
                  <div style={styles.iconWrapper}>
                    {row.videoCourses ? <FaCheck className='checkIconComparision' /> : <FaTimes className='crossIconComparision' />}
                  </div>
                </td>

                <td className='comparision-table-cell'>
                  <div style={styles.iconWrapper}>
                    {row.bootcamps ? <FaCheck className='checkIconComparision' /> : <FaTimes className='crossIconComparision' />}
                  </div>
                </td>

                <td style={{ ...styles.learnifyCell(index) }} className='comparision-table-cell'>
                  <div style={styles.iconWrapper}>
                    {row.crio ? <FaCheck className='checkIconComparision' /> : <FaTimes className='crossIconComparision' />}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='flex items-center justify-center flex-col'>
        <p className='mt-6 comparision-para'>
          Recruiters seek candidates with real project experience when hiring tech professionals, and at Learnify, we’re committed to providing exactly that. We empower learners with high-quality, hands-on learning opportunities that build skills for career growth and success.
        </p>
        <button
        onClick={()=>{setShowModal(true)}}
          className="enrollIcon mb-10"
        >
          Request Callback
        </button>
      </div>
    </div>
  );
};

const styles = {
  rowEven: {
    backgroundColor: '#FAFBFC',
  },
  rowOdd: {
    backgroundColor: '#EEF2F7',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  // Adjusted learnifyCell to alternate colors
  learnifyCell: (index) => ({
    backgroundColor: index % 2 === 0 ? '#FFFBD7' : '#FEF38D', // Alternates between two shades
    fontWeight: 'bold',
    padding: '16px',
  }),

};

// Add hover effects for icons
styles.iconWrapper[':hover'] = {
  transform: 'scale(1.1)',
};

export default Comparision;
